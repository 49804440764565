// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../rbd/pnpm-volume/0ba949a8-c87e-4efe-be8d-6670e1c7e270/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../rbd/pnpm-volume/0ba949a8-c87e-4efe-be8d-6670e1c7e270/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/pointer.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/pointer_blue.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Container */\n.pointer-widget-container {\n  position: absolute;\n  top: 106px;\n  left: 4px;\n  cursor: pointer;\n  z-index: 20;\n  background: rgba(250,250,250,0.8);\n  border-radius: 4px;\n}\n\n.pointer-widget-container:hover {\n  background: rgba(220,220,220,0.8);\n}\n\n/* Widget */\n.pointer-widget {\n  width: 32px;\n  height: 34px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center center;\n}\n\n/* Cursor */\n.pointer-widget-cursor {\n  cursor: auto;\n  cursor: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 15 15, auto !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/PointerWidget.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,eAAe;EACf,WAAW;EACX,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;AACnC;;AAEA,WAAW;AACX;EACE,WAAW;EACX,YAAY;EACZ,yDAA8C;EAC9C,4BAA4B;EAC5B,kCAAkC;AACpC;;AAEA,WAAW;AACX;EACE,YAAY;EACZ,sEAAgE;AAClE","sourcesContent":["/* Container */\n.pointer-widget-container {\n  position: absolute;\n  top: 106px;\n  left: 4px;\n  cursor: pointer;\n  z-index: 20;\n  background: rgba(250,250,250,0.8);\n  border-radius: 4px;\n}\n\n.pointer-widget-container:hover {\n  background: rgba(220,220,220,0.8);\n}\n\n/* Widget */\n.pointer-widget {\n  width: 32px;\n  height: 34px;\n  background-image: url('../assets/pointer.png');\n  background-repeat: no-repeat;\n  background-position: center center;\n}\n\n/* Cursor */\n.pointer-widget-cursor {\n  cursor: auto;\n  cursor: url('../assets/pointer_blue.png') 15 15, auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
